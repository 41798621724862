import React from 'react';
import {Text, TextPriority} from 'wix-ui-tpa/cssVars';
import {useControllerProps} from '../../../../../Widget/ControllerContext';
import {useLocaleKeys} from '../../../../../../../locale-keys/LocaleKeys';
import {AppliedCouponModel} from '../../../../../../../domain/models/AppliedCoupon.model';
import {classes} from './CollapsedShippingInfo.st.css';
import {useExperiments} from '@wix/yoshi-flow-editor';
import {SPECS} from '../../../../../constants';
import {cleanAddress} from '../../../../../../../domain/utils/cleanAddress';

export enum CollapsedShippingInfoDataHook {
  root = 'CollapsedShippingInfo.root',
  pickupDetails = 'CollapsedShippingInfo.pickupDetails',
  deliveryName = 'CollapsedShippingInfo.deliveryName',
  deliveryTime = 'CollapsedShippingInfo.deliveryTime',
  deliveryPrice = 'CollapsedShippingInfo.deliveryPrice',
}

export const CollapsedShippingInfo = () => {
  const {
    checkoutStore: {checkout},
  } = useControllerProps();
  const localeKeys = useLocaleKeys();
  const {experiments} = useExperiments();
  const pickupAddress = experiments.enabled(SPECS.FlattenPickupModelInCheckout)
    ? checkout.selectedShippingOption?.pickupAddress
    : checkout.selectedShippingOption?.pickupInfo?.address;
  const deliveryTimeText = checkout.selectedShippingOption?.hasTimeSlots
    ? checkout.selectedShippingOption?.deliveryTimeSlot
    : checkout.selectedShippingOption?.deliveryTime;

  const getPriceText = () => {
    if (
      checkout.priceSummary.shipping.amount === 0 ||
      (checkout.appliedCoupon && isFreeShippingCoupon(checkout.appliedCoupon))
    ) {
      return localeKeys.checkout.order_summary.totals.free();
    }
    return checkout.priceSummary.shipping.formattedAmount;
  };

  return experiments.enabled(SPECS.ShowTimeSlotsUIChanges) ? (
    <div data-hook={CollapsedShippingInfoDataHook.root} className={classes.root}>
      <div className={classes.details}>
        <Text priority={TextPriority.secondary}>
          <span data-hook={CollapsedShippingInfoDataHook.deliveryName}>{checkout.selectedShippingOption?.title}</span>
        </Text>
        {checkout.selectedShippingOption?.isPickup && (
          <Text priority={TextPriority.secondary} className={classes.subtitle}>
            <span data-hook={CollapsedShippingInfoDataHook.pickupDetails}>
              {cleanAddress(
                localeKeys.checkout.delivery_method.pickup_address({
                  addressLine: pickupAddress?.addressLine,
                  city: pickupAddress?.city,
                  country: pickupAddress?.countryFullname,
                  subdivision: pickupAddress?.subdivisionFullname,
                  zipCode: pickupAddress?.postalCode,
                })
              )}
            </span>
          </Text>
        )}
        {deliveryTimeText && (
          <Text
            priority={TextPriority.secondary}
            data-hook={CollapsedShippingInfoDataHook.deliveryTime}
            className={classes.subtitle}>
            {checkout.selectedShippingOption?.deliveryTime && (
              <span data-hook={CollapsedShippingInfoDataHook.deliveryTime}>{deliveryTimeText}</span>
            )}
          </Text>
        )}
      </div>
      <Text priority={TextPriority.secondary}>
        <span data-hook={CollapsedShippingInfoDataHook.deliveryPrice}> {getPriceText()}</span>
      </Text>
    </div>
  ) : (
    <Text data-hook={CollapsedShippingInfoDataHook.root} priority={TextPriority.secondary}>
      <span data-hook={CollapsedShippingInfoDataHook.deliveryName}>{checkout.selectedShippingOption?.title}</span>
      {checkout.selectedShippingOption?.deliveryTime && (
        <span data-hook={CollapsedShippingInfoDataHook.deliveryTime}>
          {` (${checkout.selectedShippingOption?.deliveryTime})`}
        </span>
      )}
      <span data-hook={CollapsedShippingInfoDataHook.deliveryPrice}> {getPriceText()}</span>
    </Text>
  );
};

const isFreeShippingCoupon = (coupon: AppliedCouponModel): boolean => coupon.couponType === 'FreeShipping';
