import React from 'react';
import {Text, Card, TextPriority} from 'wix-ui-tpa/cssVars';
import {useLocaleKeys} from '../../../locale-keys/LocaleKeys';
import {classes} from './PickupInfoBox.st.css';
import {PickupInfoModel} from '../../../domain/models/PickupInfo.model';
import {cleanAddress} from '../../../domain/utils/cleanAddress';
import {useExperiments} from '@wix/yoshi-flow-editor';
import {SPECS} from '../constants';
import {useControllerProps} from '../Widget/ControllerContext';
import {StepId} from '../../../types/app.types';
import {AddressModel} from '../../../domain/models/Address.model';

export enum PickupInfoBoxDataHook {
  root = 'PickupInfoBox.root',
  addressTitle = 'PickupInfoBox.addressTitle',
  addressDetails = 'PickupInfoBox.addressDetails',
  deliveryTimeDetails = 'PickupInfoBox.deliveryTimeDetails',
  instructionsTitle = 'PickupInfoBox.instructionsTitle',
  instructionsDetails = 'PickupInfoBox.instructionsDetails',
}

export interface PickupInfoBoxProps {
  pickupInfo?: PickupInfoModel;
  instructions?: string;
  deliveryTime?: string;
  address?: AddressModel;
  insideTimeSelector?: boolean;
}

const PickupInfoBoxInternal = ({
  pickupInfo,
  insideTimeSelector,
  instructions,
  deliveryTime,
  address,
}: PickupInfoBoxProps) => {
  const localeKeys = useLocaleKeys();
  const {experiments} = useExperiments();
  const {
    stepsManagerStore: {activeStep},
  } = useControllerProps();
  const currentInstructions = experiments.enabled(SPECS.FlattenPickupModelInCheckout)
    ? instructions
    : pickupInfo?.instructions;
  const currentDeliveryTime = experiments.enabled(SPECS.FlattenPickupModelInCheckout)
    ? deliveryTime
    : pickupInfo?.deliveryTime;
  const shouldShowDeliveryTime = currentDeliveryTime && activeStep.stepId === StepId.deliveryMethod;
  const currentAddress = experiments.enabled(SPECS.FlattenPickupModelInCheckout) ? address : pickupInfo?.address;

  // eslint-disable-next-line no-nested-ternary
  return experiments.enabled(SPECS.ShowTimeSlotsUIChanges) ? (
    currentInstructions ? (
      <div className={insideTimeSelector ? classes.insideTimeSelector : classes.root}>
        <Card data-hook={PickupInfoBoxDataHook.root} className={classes.card}>
          <Card.Container>
            {shouldShowDeliveryTime && (
              <Text
                data-hook={PickupInfoBoxDataHook.deliveryTimeDetails}
                priority={TextPriority.secondary}
                className={classes.deliveryTime}>
                {currentDeliveryTime}
              </Text>
            )}
            <Text
              tagName={'h4'}
              data-hook={PickupInfoBoxDataHook.instructionsTitle}
              priority={TextPriority.primary}
              className={classes.title}>
              {localeKeys.checkout.pickup_details_form.store_pickup_instructions_header()}
            </Text>
            <Text
              data-hook={PickupInfoBoxDataHook.instructionsDetails}
              priority={TextPriority.secondary}
              className={classes.instructions}>
              {currentInstructions}
            </Text>
          </Card.Container>
        </Card>
      </div>
    ) : null
  ) : (
    <div className={classes.root}>
      <Card data-hook={PickupInfoBoxDataHook.root} className={classes.card}>
        {!!currentAddress && (
          <Card.Container>
            <Text
              tagName={'h4'}
              data-hook={PickupInfoBoxDataHook.addressTitle}
              priority={TextPriority.primary}
              className={classes.title}>
              {localeKeys.checkout.pickup_details_form.store_pickup_address_header()}
            </Text>
            <Text data-hook={PickupInfoBoxDataHook.addressDetails} priority={TextPriority.secondary}>
              {cleanAddress(
                localeKeys.checkout.delivery_method.pickup_address({
                  addressLine: currentAddress?.addressLine,
                  city: currentAddress?.city,
                  country: currentAddress?.countryFullname,
                  subdivision: currentAddress?.subdivisionFullname,
                  zipCode: currentAddress?.postalCode,
                })
              )}
            </Text>
          </Card.Container>
        )}
        {!!currentDeliveryTime && (
          <Card.Container>
            <Text data-hook={PickupInfoBoxDataHook.deliveryTimeDetails} priority={TextPriority.secondary}>
              {currentDeliveryTime}
            </Text>
          </Card.Container>
        )}
        {!!currentInstructions && (
          <Card.Container>
            <Text
              tagName={'h4'}
              data-hook={PickupInfoBoxDataHook.instructionsTitle}
              priority={TextPriority.primary}
              className={classes.title}>
              {localeKeys.checkout.pickup_details_form.store_pickup_instructions_header()}
            </Text>
            <Text
              data-hook={PickupInfoBoxDataHook.instructionsDetails}
              priority={TextPriority.secondary}
              className={classes.instructions}>
              {currentInstructions}
            </Text>
          </Card.Container>
        )}
      </Card>
    </div>
  );
};

export const PickupInfoBox = React.memo(PickupInfoBoxInternal);
